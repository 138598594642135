import {Component, Inject} from '@angular/core';
import {TOOLTIP_CONTENT_DATA} from '../../../shared/directive/tooltip/tooltip-content-token';

@Component({
  selector: 'app-tooltip-waterfall-ad-unit',
  templateUrl: './tooltip-waterfall-ad-unit.component.html',
  styleUrl: './tooltip-waterfall-ad-unit.component.scss'
})
export class TooltipWaterfallAdUnitComponent {
  /**
   * Constructor for TooltipWaterfallAdUnitComponent.
   * Injects tooltip content data provided by the parent component.
   * @param data The data to be displayed in the tooltip panel.
   */
  constructor(@Inject(TOOLTIP_CONTENT_DATA) public data: { text: string, isPopup: boolean }) {
  }
}

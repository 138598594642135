<div class="h-100 align-center">
  <ng-container *ngIf="tableCell?.tooltip">
    <div class="d-inline-block h-100" [tooltipType]="tableCell.tooltip.type"
         [placement]="tableCell.tooltip.placement"
         [contentCmp]="tableCell.tooltip.contentComponent"
         [appTooltip]="tableCell.tooltip.text"
         [tooltipCss]="tableCell.tooltip?.cssClass"
         [cursorAllowedInsideTooltip]="tableCell.tooltip?.cursorAllowedInsideTooltip === true">
      <ng-container *ngTemplateOutlet="cellData"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!(tableCell?.tooltip) && !(tableCell.multiValue)">
    <ng-container *ngTemplateOutlet="cellData"></ng-container>
  </ng-container>

  <ng-container *ngIf="tableCell.multiValue">
    <div [style.row-gap.px]="tableCell.multiValue.length > 2 ? 5 : 10" class="multi-value-wrapper">
      <ng-container *ngFor="let cell of tableCell.multiValue">
        <ng-container  *ngTemplateOutlet="suffixIcons; context: {cell: cell}"></ng-container>
        <ng-container *ngTemplateOutlet="regularCell; context: {cell: cell}"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #cellData>
    <span (click)="onTableCellClicked()" [ngClass]="tableCell?.cssClass ? tableCell.cssClass : ''"
          class="cell-wrapper">
      <ng-container  *ngTemplateOutlet="suffixIcons; context: {cell: tableCell}"></ng-container>

      <ng-container *ngIf="tableCell?.tags?.length">
        <ng-container *ngTemplateOutlet="cellWithTags"></ng-container>
      </ng-container>
      <ng-container *ngIf="!(tableCell?.tags?.length)">
        <ng-container *ngTemplateOutlet="regularCell;context: {cell: tableCell}"></ng-container>
      </ng-container>

  </span>
</ng-template>



<ng-template let-cell="cell" #regularCell>
  <span class="cell-with-ellipsis">{{getCellVal(cell)}}</span>
</ng-template>



<ng-template #cellWithTags>
  <span #lengthChecker class="hidden-cell-value cell-with-ellipsis">{{getCellVal(tableCell)}}</span>
  <span *ngIf="shouldShowTooltipForEllipsis(lengthChecker)" class="cell-with-ellipsis">
      {{getCellVal(tableCell)}}
    </span>
  <span *ngIf="!shouldShowTooltipForEllipsis(lengthChecker)" class="cell-with-ellipsis">
      {{getCellVal(tableCell)}}
    </span>


  <app-tag *ngFor="let tag of tableCell.tags.slice(0,3)" [name]="tag"></app-tag>
  <span *ngIf="tableCell.tags.length > 3">...</span>
</ng-template>


<ng-template #suffixIcons let-cell="cell">
  <div class="suffix-icons" *ngIf="getCell(cell)?.icons?.length">
    <ng-container *ngFor="let icon of getCell(cell)?.icons">
      <fa-icon *ngIf="icon?.type === IconOriginType.EXTERNAL_LIBRARY"
               [ngClass]="icon.class ? icon.class : ''"
               [icon]="icon.iconRef"
               class="table-icon"
               [appTooltip]="icon.tooltip?.text"
               [isActive]="!!icon.tooltip"
               [contentCmp]="icon.tooltip?.contentComponent"
                [tooltipType]="icon.tooltip?.type"
               [tooltipCss]="icon.tooltip?.cssClass"
               [cursorAllowedInsideTooltip]="icon.tooltip?.cursorAllowedInsideTooltip === true">
      </fa-icon>
      <div [ngClass]="icon.class ? icon.class : ''" *ngIf="icon?.type === IconOriginType.CUSTOM" class="table-icon">
        <app-custom-icon
          [color]="icon.color ? icon.color : Color.PRIMARY"
          [appTooltip]="icon.tooltip?.text"
          [isActive]="!!icon.tooltip"
          [tooltipCss]="icon.tooltip?.cssClass"
          [contentCmp]="icon.tooltip?.contentComponent"
          [tooltipType]="icon.tooltip?.type"
          [cursorAllowedInsideTooltip]="icon.tooltip?.cursorAllowedInsideTooltip === true"
          [heightLiteral]="'100%'"
          [name]="icon.iconName"></app-custom-icon>
      </div>
    </ng-container>
  </div>
</ng-template>

import {Injectable} from '@angular/core';
import {RequestType} from 'src/app/shared/enum/RequestType';
import {IProductRaw} from 'src/app/product/interface/IProductRaw';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import {Observable} from 'rxjs';
import {IProductDetails} from '../interface/IProductDetails';
import {map} from 'rxjs/operators';
import {compareAlphabetical} from '../../shared/util/table/sort-utils';
import {ProductQueryRaw} from '../interface/ProductQueryRaw';
import {QueryService} from '../../shared/service/query.service';
import {ProductList} from '../interface/ProductList';
import {ProductSimple} from '../interface/IProductSimple';
import {DisabledAdUnitForPlatform} from '../interface/switch-on-off/DisabledAdUnitForPlatform';

@Injectable({
  providedIn: 'root'
})
export class ApiProductService {

  constructor(
    private apiGeneralService: ApiGeneralService,
    private queryService: QueryService
  ) {
  }

  public getProducts(): Observable<ProductSimple[]> {
    const url = 'Products';

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }

  public getProductsPaginated(query: ProductQueryRaw): Observable<ProductList> {
    const params = this.queryService.getHttpParams(query);
    const url = 'Products/PaginatedAndFiltered';

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    });
  }

  public getProductSearchAutocomplete(): Observable<string[]> {
    const url = 'Products/Names';

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    }).pipe(map((values: string[]) => values.filter(value => !!value)));
  }

  public getProduct(id: string): Observable<IProductDetails> {
    const url = `Products/GetByInternalId/${id}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    }).pipe(map((product: IProductDetails) => {
      product.platforms?.forEach(platform => {
        platform.externalTrackings?.sort((a, b) => compareAlphabetical(a?.name, b?.name));
      });
      return product;
    }));
  }

  public getProductByBoombitId(boombitId: string): Observable<IProductDetails> {
    const url = 'Products/GetByBoombitId/' + encodeURIComponent(boombitId);
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    }).pipe(map((product: IProductDetails) => {
      product.platforms?.forEach(platform => {
        platform.externalTrackings?.sort((a, b) => compareAlphabetical(a?.name, b?.name));
      });
      return product;
    }));
  }

  public createProduct(product: IProductRaw): Observable<void> {
    const url = 'Products';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: product
    });
  }

  public updateProduct(product: IProductRaw): Observable<void> {
    const url = 'Products';
    return this.apiGeneralService.sendRequest({
      method: RequestType.put,
      url,
      data: product
    });
  }

  public deleteProduct(id: string): Observable<string> {
    const url = `Products/${id}`;

    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url,
    });
  }

  public validateProductName(name: string): Observable<boolean | void> {
    const url = `Products/ValidateName/${encodeURIComponent(name)}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }

  public validateProductBoombitId(name: string): Observable<boolean | void> {
    const url = 'Products/ValidateBoombitId/' + encodeURIComponent(name);
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }


  public editWaterfallsOrder(productId: string, waterfallIds: string[]): Observable<void> {
    const url = `Products/EditOrderOfWaterfalls/${productId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.put,
      data: waterfallIds,
      url,
    });
  }

  public getDisabledAdUnitsForPlatform(): Observable<DisabledAdUnitForPlatform[]> {
    const url = 'Products/DisabledAdUnits';

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }

}

import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {faCheck, faXmark} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-toggle-slider',
  templateUrl: './toggle-slider.component.html',
  styleUrls: ['./toggle-slider.component.scss']
})
export class ToggleSliderComponent implements ControlValueAccessor {
  @Input() preventChange = false;
  public isChecked = false;

  public faCheck = faCheck;
  public faXMark = faXmark;

  public onChange: any = () => {
  }
  public onTouched: any = () => {
  }

  constructor(public control: NgControl) {
    this.control.valueAccessor = this;
  }

  public onToggle(): void {
    this.isChecked = !this.isChecked;
    this.onChange(this.isChecked);
    this.onTouched();
  }

  public onClick(event: Event): void {
    if(this.preventChange) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /* ControlValueAccessor */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    if (obj === undefined || obj === null) {
      this.onChange(false);
      this.onTouched();
      return;
    }
    this.isChecked = obj;
  }
}

import {Injectable} from '@angular/core';
import {IProduct} from 'src/app/product/interface/IProduct';
import {ApiProductService} from '../api-product.service';
import {IProductRaw} from '../../interface/IProductRaw';
import {firstValueFrom, Subject} from 'rxjs';
import {IProductForm} from '../../interface/IProductForm';
import {isValueEmpty} from '../../../shared/util/form/form-utils';
import {getPlatformRaw, getUpdatedPlatformRaw} from '../../util/product-raw-utils';
import {IBasicSelectOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import _ from 'lodash';
import {IProductDetails} from '../../interface/IProductDetails';
import {ProductTag} from '../../enum/ProductTag';
import {ScreenOrientation} from '../../enum/ScreenOrientation';
import {PlatformService} from '../platform.service';
import {AppInjector} from '../../../shared/util/app-injector';
import {ExternalTrackingManager} from '../../class/external-tracking-manager';
import {ApiErrorService} from '../../../shared/service/api/api-error.service';
import {ProductQueryRaw} from '../../interface/ProductQueryRaw';
import {ProductList} from '../../interface/ProductList';
import {ProductChange} from '../../interface/ProductChange';
import {ProductSimple} from '../../interface/IProductSimple';
import {DisabledAdUnitForPlatform} from '../../interface/switch-on-off/DisabledAdUnitForPlatform';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public productChangedSubject: Subject<void | ProductChange> = new Subject<void | ProductChange>();

  constructor(
    private apiProductService: ApiProductService, private apiErrorService: ApiErrorService) {
  }

  private static getTagsRaw(tags: IBasicSelectOption[], shouldAddNewTag: boolean): string[] {
    const tagRes: string[] = tags ? tags.map(tag => tag.name) : [];
    if (shouldAddNewTag) {
      tagRes.push(ProductTag.NEW);
    }
    return _.uniq(tagRes);
  }

  public async getProducts(): Promise<ProductSimple[]> {
    try {
      return await firstValueFrom(this.apiProductService.getProducts());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.getProductsError');
      throw error;
    }
  }

  public async getProductsPaginated(query: ProductQueryRaw): Promise<ProductList> {
    try {
      return await firstValueFrom(this.apiProductService.getProductsPaginated(query));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.getProductsError');
      throw error;
    }
  }

  public async getProductSearchAutocomplete(): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiProductService.getProductSearchAutocomplete());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.getProductSearchAutocompleteError');
      throw error;
    }
  }

  public async getProductByInternalId(productId: string): Promise<IProductDetails> {
    try {
      return await firstValueFrom(this.apiProductService.getProduct(productId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.getProductError');
      throw error;
    }
  }

  public async getProductByBoombitId(boombitId: string): Promise<IProductDetails> {
    try {
      return await firstValueFrom(this.apiProductService.getProductByBoombitId(boombitId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.getProductError');
      throw error;
    }
  }

  public async createProduct(product: IProductForm): Promise<void> {
    const rawProduct: IProductRaw = {
      name: product.name,
      boombitId: product.boombitId,
      tags: ProductsService.getTagsRaw(product.tags, true),
      iconURL: product.iconURL,
      screenOrientation: product.isLandscape ? ScreenOrientation.LANDSCAPE : ScreenOrientation.PORTRAIT,
      platforms: product.platforms.filter(item => !isValueEmpty(item.type.name)).map(item => getPlatformRaw(item))
    };

    try {
      await firstValueFrom(this.apiProductService.createProduct(rawProduct));
      this.productChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.productCreationErrorMessage');
      throw error;
    }
  }

  public async updateProduct(product: IProductForm, initialProduct: IProductDetails): Promise<void> {
    const rawProduct: IProductRaw = {
      id: product.id,
      name: product.name,
      boombitId: product.boombitId,
      tags: ProductsService.getTagsRaw(product.tags, initialProduct?.tags?.includes(ProductTag.NEW)),
      iconURL: product.iconURL,
      screenOrientation: product.isLandscape ? ScreenOrientation.LANDSCAPE : ScreenOrientation.PORTRAIT,
      platforms: product.platforms.filter(item => !isValueEmpty(item.type.name)).map(item => getUpdatedPlatformRaw(item)),
      orderOfWaterfalls: initialProduct.assignedWaterfalls?.map(waterfall => waterfall.id)
    };
    await this.deleteTrackings(rawProduct, initialProduct);

    try {
      await firstValueFrom(this.apiProductService.updateProduct(rawProduct));
      this.productChangedSubject.next({editId: product.id, productEdited: rawProduct});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.productEditErrorMessage');
      throw error;
    }

  }

  private async deleteTrackings(product: IProductRaw, initialProduct: IProductDetails): Promise<void> {
    const platformService = AppInjector.get(PlatformService);
    const trackingManager = new ExternalTrackingManager();
    const idsToDelete: string[] = trackingManager.getTrackingsToDelete(product, initialProduct);
    const promises = idsToDelete.map(id => platformService.deleteExternalTracking(id));
    await Promise.all(promises).catch((error) => {throw error;});
  }

  public async deleteProduct(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiProductService.deleteProduct(id));
      this.productChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.deleteProductError');
      throw error;
    }
  }

  public async validateProductName(name: string): Promise<boolean> {
    try {
      return await firstValueFrom(this.apiProductService.validateProductName(name)) as boolean;
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.validateProductNameError');
      throw error;
    }
  }

  public async validateProductBoombitId(name: string): Promise<boolean> {
    try {
      return await firstValueFrom(this.apiProductService.validateProductBoombitId(name)) as boolean;
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.validateProductBoombitIdError');
      throw error;
    }
  }

  public async editWaterfallsOrder(productId: string, waterfallIds: string[]): Promise<void> {
    try {
      return await firstValueFrom(this.apiProductService.editWaterfallsOrder(productId, waterfallIds));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfallOrder.waterfallOrderEditErrorMessage');
      throw error;
    }
  }

  public async getDisabledAdUnitsForPlatform(): Promise<DisabledAdUnitForPlatform[]> {
    try {
      return await firstValueFrom(this.apiProductService.getDisabledAdUnitsForPlatform());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'product.getProductsError');
      throw error;
    }
  }
}

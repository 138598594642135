<app-base-side-dialog [dialogRef]="dialogRef" [showBackButton]="!!backAction?.backActionDialogConfig" [backAction]="backAction">
  <app-spinner *ngIf="isLoading"></app-spinner>

  <div class="preview-content" *ngIf="waterfall">
    <div class="text-light side-dialog--padding-horizontal preview-header">
      <p class="mat-body-2 color-dark-grey-fourth">{{"waterfall.waterfallPreview.waterfallPreview" | translate}}</p>

      <div class="side-dialog-header-row">
        <h1>{{waterfall.name}}</h1>
      </div>
      <div class="side-dialog-header-row">
        <p>
          {{"product.productForm.productName" | translate}}: {{waterfall.product.name}}
        </p>
        <p>
          {{"waterfall.waterfallForm.regionLabel" | translate}}: {{getRegionsNameShort(waterfall.regions).join(', ') || '-'}}
        </p>
        <div [ngClass]="{'center-gap-10': waterfall.platform, 'center-gap-5': !waterfall.platform}">
          <p>{{"waterfall.waterfallForm.platformLabel" | translate}}: </p>
          <app-platforms-icons [platformTypes]="waterfall.platform ? [waterfall.platform?.type] : []"></app-platforms-icons>
        </div>


        <div *ngIf="waterfall?.adUnits?.length" class="center-gap-10">
          <p>{{"adUnit.adUnits" | translate}}:</p>
          <app-ad-unit-icons [adUnitTypes]="adUnitTypes"></app-ad-unit-icons>
        </div>
        <div *ngIf="!waterfall?.adUnits?.length"  class="center-gap-5">
          <p>{{"adUnit.adUnits" | translate}}:</p>
          <p class="text-14-txt-2-light">{{"adUnit.noAdUnits" | translate}}</p>
        </div>

        <div class="center-gap-5">
          <p>{{"waterfall.waterfallPreview.confirmationPopup" | translate}}:</p>
          <ng-container *ngIf="waterfall.confirmationPopup?.length > 0">
            {{waterfall.confirmationPopup.join(', ')}}
          </ng-container>
          <ng-container *ngIf="waterfall.confirmationPopup?.length === 0">
            -
          </ng-container>
        </div>

      </div>
    </div>

    <hr class="separator separator--no-margin"/>
    <div class="side-dialog--padding-horizontal preview-body--scrollable">
      <p *ngIf="productCampaignsIterator.length === 0" class="text-16-txt-1-light">{{'waterfall.noWaterfalls' | translate}}</p>
      <div *ngIf="productCampaignsIterator.length > 0" class="padding-t-20 product-campaigns-list">
        <tr>
          <th class="text-16-txt-1-semi-bold mat-body">{{'waterfall.waterfallTableHeaders.destinations' | translate}}</th>
          <th class="text-16-txt-1-semi-bold mat-body">{{'waterfall.waterfallTableHeaders.campaigns' | translate}}</th>
        </tr>
        <tr *ngFor="let item of productCampaignsIterator; let i = index">
          <td class="mat-body">
            {{(i + 1) + '.'}} {{waterfall.promotedProducts[i]?.name || '-'}}
            <fa-icon *ngIf="waterfall.promotedProducts[i]?.id"
                     (click)="onProductPreviewClicked(waterfall.promotedProducts[i]?.id)"
                     class="color-primary cursor-pointer action-icon"
                     [appIconTooltip]="faEye" [icon]="faEye">
            </fa-icon>
          </td>
          <td class="mat-body">
            {{(i + 1) + '.'}}  {{waterfall.campaigns[i]?.name || ('campaign.noCampaign' | translate)}}
            <fa-icon *ngIf="waterfall.campaigns[i]?.id"
                     (click)="onCampaignPreviewClicked(waterfall.campaigns[i]?.id)"
                     class="color-primary cursor-pointer action-icon"
                     [appIconTooltip]="faEye"
                     [icon]="faEye">
            </fa-icon>
          </td>
        </tr>
      </div>

      <div class="side-dialog-action-buttons">
        <button (click)="onCloseClicked()" mat-stroked-button color="primary">
          <fa-icon [icon]="faXMark" class="close-icon-big"></fa-icon>
          {{"button.close" | translate}}
        </button>
        <button (click)="onDeleteWaterfallClicked()" mat-flat-button color="primary">
          <fa-icon [icon]="faTrash"></fa-icon>
          {{"waterfall.deleteWaterfall" | translate}}
        </button>
        <button (click)="onEditWaterfallClicked()" mat-flat-button color="primary">
          <fa-icon [icon]="faEdit"></fa-icon>
          {{"waterfall.editWaterfall" | translate}}
        </button>
      </div>
    </div>
  </div>
</app-base-side-dialog>

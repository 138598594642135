import {Injectable} from '@angular/core';
import {IAdUnit} from 'src/app/ad-unit/interface/IAdUnit';
import {ApiAdUnitService} from './api-ad-unit.service';
import {BehaviorSubject, firstValueFrom, Observable, skip} from 'rxjs';
import {IAdUnitFormat} from '../interface/IAdUnitFormat';
import {SnackbarService} from '../../shared/service/snackbar/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {IAdUnitSmallInfo} from '../interface/IAdUnitSmallInfo';

/**
 * Service responsible for managing and fetching Ad Units and related information.
 */
@Injectable({
  providedIn: 'root'
})
export class AdUnitService {
  private dataLoaded = false;
  private adUnits: IAdUnit[];
  private adUnitFormats: IAdUnitFormat[];
  private adUnitsBehaviourSubject: BehaviorSubject<IAdUnit[]> = new BehaviorSubject<IAdUnit[]>(null);
  private adUnitFormatsBehaviourSubject: BehaviorSubject<IAdUnitFormat[]> = new BehaviorSubject<IAdUnitFormat[]>(null);

  /**
   * Constructor for AdUnitService.
   * @param apiAdUnitService The service responsible for making API calls related to Ad Units.
   * @param snackBarService Service for displaying Snackbar messages.
   * @param translateService TranslateService for language translation.
   * @param apiErrorService Service for handling API errors.
   */
  constructor(
    private apiAdUnitService: ApiAdUnitService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    private apiErrorService: ApiErrorService
  ) {
    this.fetchAvailableAdUnits();
  }

  /** Fetches available Ad Units and their formats from the API. */
  public async fetchAvailableAdUnits(): Promise<void> {
    this.adUnits = await this.getAdUnits();
    this.adUnitFormats = [].concat(...this.adUnits.map(adUnit => adUnit.adUnitFormats));
    this.dataLoaded = true;
    this.adUnitsBehaviourSubject.next(this.adUnits);
    this.adUnitFormatsBehaviourSubject.next(this.adUnitFormats);
  }

  /**
   * Retrieves Ad Units from the API.
   * @returns Promise with the list of Ad Units.
   */
  public async getAdUnits(): Promise<IAdUnit[]> {
    let adUnits = [];
    try {
      adUnits = await firstValueFrom(this.apiAdUnitService.getAllAdUnits());
    } catch (error) {
      this.showAdUnitsFailedMsg(error);
      throw error;
    }
    return adUnits;
  }

  /**
   * Retrieves Ad Units from the API.
   * @returns Promise with the list of Ad Units.
   */
  public async getAdUnitsDisabledMaster(): Promise<IAdUnitSmallInfo[]> {
    let adUnits = [];
    try {
      adUnits = await firstValueFrom(this.apiAdUnitService.getAdUnitsDisabledMaster());
    } catch (error) {
      this.showAdUnitsFailedMsg(error);
      throw error;
    }
    return adUnits;
  }

  /**
   * Displays a Snackbar message when fetching Ad Units fails.
   * Some ad-blocking plugins may block the request to fetch ad units,
   * resulting in a status code of 0. In such cases, the 'adUnit.adBlockerError' message is displayed.
   * @param error HttpErrorResponse object representing the error.
   */
  private async showAdUnitsFailedMsg(error: HttpErrorResponse): Promise<void> {
    const msgKey = AdUnitService.apiError(error) ? 'adUnit.getAdUnitsError' : 'adUnit.adBlockerError';
    this.apiErrorService.showApiErrorSnackbar(error, msgKey);
  }

  /**
   * Checks if the error is an API error based on the HTTP status code.
   * @param error HttpErrorResponse object representing the error.
   * @returns `true` if the error is an API error, otherwise `false`.
   */
  private static apiError(error: HttpErrorResponse): boolean {
    return /^\d{3}$/.test(error?.status?.toString());
  }

  /**
   * Retrieves Ad Units from the BehaviorSubject.
   * @returns Promise with the list of Ad Units.
   */
  public async getAdUnitsFromSubject(): Promise<IAdUnit[]> {
    if (this.dataLoaded) {
      return this.adUnits;
    } else {
      return firstValueFrom(this.adUnitsBehaviourSubject.pipe(skip(1)));
    }
  }

  /**
   * Retrieves Ad Unit Formats from the BehaviorSubject.
   * @returns Promise with the list of Ad Unit Formats.
   */
  public async getAdUnitFormatsFromSubject(): Promise<IAdUnitFormat[]> {
    if (this.dataLoaded) {
      return this.adUnitFormats;
    } else {
      return firstValueFrom(this.adUnitFormatsBehaviourSubject.pipe(skip(1)));
    }
  }

  /**
   * Gets an observable for Ad Units.
   * @returns Observable with the list of Ad Units.
   */
  public get adUnitsObservable(): Observable<IAdUnit[]> {
    return this.adUnitsBehaviourSubject.asObservable();
  }
}
